import { useEffect, useState } from "react";
import HeaderShop from "../../../components/HeaderShop/Header";
import {
    Form,
    Table,
    Button,
    Modal,
    Input,
    Avatar,
    Card,
    InputNumber,
    message,
    Select,
} from "antd";
import { host } from "../../../config";
import { useNavigate } from "react-router-dom";
const { Meta } = Card;
export default function App() {
    const token = localStorage.getItem("token");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [config, setConfig] = useState();
    const [options, setOptions] = useState();
    const [data, setData] = useState();
    const [TableData, setTableData] = useState();
    const [selectedElement, setSelectedElement] = useState();
    const navigate = useNavigate();
    const screenWidth = window.innerWidth;

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const selectFilter = (owner, index) => {
        var filteredData = data.filter((e) => e.owner === owner);
        setSelectedElement(index);
        setTableData(filteredData);
    };
    const fetchQrcode = (e) => {
        if (e) {
            fetch(host + "/api/base/jizzax", {
                method: "PUT",
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify({
                    qrcode: e,
                }),
            })
                .then((res) => res.json())
                .then((res) => {
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                    message.error(err);
                });
        }
    };

    useEffect(() => {
        fetch(host + "/api/config", {
            method: "GET",
            headers: {
                Authorization: "Bareer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setConfig(res);
            })
            .catch((err) => console.log(err.message));

        fetch(host + `/api/invoice`, {
            method: "POST",
            headers: {
                Authorization: "Bareer " + token,
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify({
                date: config?.date,
            }),
        })
            .then((res) => res.json())
            .catch((err) => console.log(err.message));

        fetch(host + "/api/database/onlynames", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => setOptions(res));
    }, []);

    const fetchPost = (e) => {
        fetch(host + "/api/base/jizzax", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(e),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                message.error(err);
            });
    };

    const fetchDelet = (e) => {
        fetch(host + "/api/base", {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                _id: e,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => console.log(err.message));
    };

    useEffect(() => {
        fetch(host + "/api/base", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setData(res);
                setTableData(res);
            })
            .catch((err) => {
                console.log(err);
                message.error(err);
            });
    }, []);

    const columns = [
        {
            title: "T/R",
            dataIndex: "index",
            render(record, index) {
                return {
                    children: <div>{data.indexOf(index) + 1}</div>,
                };
            },
        },
        {
            title: "Model Nomi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Model Bazasi",
            dataIndex: "owner",
            key: "owner",
        },
        {
            title: "Vesi",
            dataIndex: "",
            key: "weight",
            render: (e) => <p>{e.weight.toFixed(2)}</p>,
        },
        {
            title: "Ustidigi molda",
            dataIndex: "",
            key: "plus",
            render: (e) => <p>{e.plus.toFixed(2)}</p>,
        },
        {
            title: "O'chirish",
            dataIndex: "",
            key: "x",
            render: (e) => (
                <Button type="primary" onClick={() => fetchDelet(e._id)} danger>
                    Delete
                </Button>
            ),
        },
    ];

    const sums = {};

    if (data) {
        for (const item of data) {
            const owner = item.owner;
            const weight = item.weight;
            const plus = item.plus;

            if (sums[owner]) {
                sums[owner].weight += weight;
                sums[owner].plus += plus;
            } else {
                sums[owner] = { weight, plus };
            }
        }
    }
    return (
        <>
            <HeaderShop config={config} />
            <div className="center">
                <h1 style={{ padding: "20px" }}>Bazadan ayirish</h1>
                <Input
                    style={{
                        width: "350px",
                    }}
                    autoFocus={screenWidth < 768 ? false : true}
                    name="qrcode"
                    onBlur={(e) => fetchQrcode(e.target.value)}
                    onKeyDown={(e) =>
                        e.code === "Enter" ? fetchQrcode(e.target.value) : ""
                    }
                    placeholder="Qr code..."
                />
                <>
                    <div
                        className="flex"
                        style={{
                            justifyContent: "space-around",
                            margin: "30px auto",
                        }}
                    >
                        {Object.entries(sums).map(([owner, values], index) => (
                            <div key={owner}>
                                <Card
                                    className="dashboard-card"
                                    onClick={(e) => {
                                        selectFilter(owner, index);
                                    }}
                                    style={{
                                        border:
                                            selectedElement === index
                                                ? "2px solid #1890ff"
                                                : "",
                                        cursor: "pointer",
                                        width: 200,
                                        verticalAlign: "",
                                    }}
                                >
                                    <Meta
                                        avatar={
                                            <Avatar
                                                className="avatarIcon"
                                                size={"large"}
                                            >
                                                {owner}
                                            </Avatar>
                                        }
                                        title={values.weight.toFixed(2)}
                                        description={values.plus.toFixed(2)}
                                    />
                                </Card>
                            </div>
                        ))}
                        {/* {data?.map((e) => {
                            return (
                                
                            );
                        })} */}
                    </div>
                    <Table
                        columns={columns}
                        scroll={{ x: 1500, y: 400 }}
                        expandable={{
                            expandedRowRender: (record) => (
                                <p
                                    style={{
                                        margin: 0,
                                    }}
                                >
                                    {record.description}
                                </p>
                            ),
                            rowExpandable: (record) => record.description,
                        }}
                        dataSource={TableData}
                        pagination={false}
                    />
                    <Button type="primary" onClick={showModal}>
                        Model ayirish
                    </Button>
                    <Modal
                        footer={null}
                        title="Model Ayirishs"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <Form
                            onFinish={(e) => {
                                fetchPost(e);
                            }}
                            style={{
                                maxWidth: 300,
                                margin: "0 auto",
                            }}
                        >
                            <Form.Item
                                name="name"
                                label="Nomi"
                                rules={[
                                    {
                                        required: true,
                                        message: "Nomini yozing",
                                    },
                                ]}
                            >
                                <Input placeholder="Model nomini tanlang" />
                            </Form.Item>
                            <Form.Item
                                name="weight"
                                label="Vesi"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vesini yozing",
                                    },
                                ]}
                            >
                                <Form.Item name="weight" noStyle>
                                    <InputNumber
                                        style={{
                                            width: "auto",
                                        }}
                                        min={0.01}
                                        max={999.99}
                                    />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item name="price" label="Narxi">
                                <Form.Item name="price" noStyle>
                                    <InputNumber
                                        style={{
                                            width: "auto",
                                        }}
                                    />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item
                                name="owner"
                                label="Baza"
                                rules={[
                                    {
                                        required: true,
                                        message: "Baza harfini yozing",
                                    },
                                ]}
                            >
                                <Input placeholder="" />
                            </Form.Item>
                            <Button
                                type="primary"
                                style={{
                                    width: "100%",
                                }}
                                htmlType="submit"
                            >
                                Qo'shish
                            </Button>
                        </Form>
                    </Modal>
                </>
            </div>
        </>
    );
}
