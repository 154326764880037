import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import Temp from "./pages/Template/Template";
import Shop from "./pages/Shop/Shop";
import ClientsShop from "./pages/Shop/Clients/Clients";
import Inner from "./pages/Shop/Clients/Inner/Inner";
import DataBase from "./pages/Shop/Database/Database";
import Base from "./pages/Shop/Base/Base";
import Jizzax from "./pages/Shop/Jizzax/Jizzax";
import Print from "./pages/Print/Print.jsx";
import Print2 from "./pages/Print/Print2.jsx";
import InnerShop from "./pages/Shop/InnerShop/Inner";
import Seller from "./pages/Shop/Seller/Seller.jsx";
import History from "./pages/Shop/History/Cloud.jsx";
import Cloud from "./pages/Shop/Cloud/Cloud";

export default function App() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Temp />} />
            <Route path="/print" element={<Print />} />
            <Route path="/print2" element={<Print2 />} />
            <Route path="/clients" element={<Temp />} />
            <Route path="/workers" element={<Temp />} />
            <Route path="/money" element={<Temp />} />
            <Route path="/gold" element={<Temp />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop/clients" element={<ClientsShop />} />
            <Route path="/shop/sellers" element={<Seller />} />
            <Route path="/shop/clients/:id" element={<Inner />} />
            <Route path="/room/:id" element={<InnerShop />} />
            <Route path="/shop/database" element={<DataBase />} />
            <Route path="/shop/base" element={<Base />} />
            <Route path="/shop/cloud" element={<Cloud />} />
            <Route path="/shop/history" element={<History />} />
            <Route path="/shop/jizzax" element={<Jizzax />} />
        </Routes>
    );
}
