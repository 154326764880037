import { useEffect, useState } from "react";
import HeaderShop from "../../../components/HeaderShop/Header";
import { Form, Table, Button, Modal, Input } from "antd";
import { host } from "../../../config";

export default function App() {
  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [config, setConfig] = useState();
  const [data, setData] = useState();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const fetchPost = (e) => {
    fetch(host + "/api/database", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(e),
    })
      .then((res) => res.json())
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => console.log(err.message));
  };

  const fetchDelet = (id) => {
    fetch(host + "/api/database", {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    fetch(host + "/api/config", {
      method: "GET",
      headers: {
        Authorization: "Bareer " + token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setConfig(res);
      })
      .catch((err) => console.log(err.message));
    fetch(host + `/api/invoice`, {
      method: "POST",
      headers: {
        Authorization: "Bareer " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify({
        date: config?.date,
      }),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err.message));
  }, []);

  useEffect(() => {
    fetch(host + "/api/database", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      })
      .catch((err) => console.log(err.message));
  }, [token]);

  const columns = [
    {
      title: "T/R",
      dataIndex: "index",
      render(record, index) {
        return {
          children: <div>{data.indexOf(index) + 1}</div>,
        };
      },
    },
    {
      title: "Model Nomi",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ustidigi puli",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "O'chirish",
      dataIndex: "",
      key: "x",
      render: (e) => (
        <Button type="primary" onClick={() => fetchDelet(e._id)} danger>
          Delete
        </Button>
      ),
    },
  ];
  return (
    <>
      <HeaderShop config={config} />
      <div className="center">
        <h1 style={{ padding: "20px" }}>Ma'lumotlar bazasi</h1>

        <>
          <Table
            columns={columns}
            scroll={{ x: 1500, y: 400 }}
            expandable={{
              expandedRowRender: (record) => (
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  {record.description}
                </p>
              ),
              rowExpandable: (record) => record.description,
            }}
            dataSource={data}
            pagination={false}
          />
          <Button type="primary" onClick={showModal}>
            Yangi model
          </Button>
          <Modal
            title="Yangi model qo'shish"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form
              onFinish={(e) => {
                fetchPost(e);
              }}
              style={{
                maxWidth: 300,
                margin: "0 auto",
              }}
            >
              <Form.Item
                name="name"
                label="Nomi"
                rules={[
                  {
                    required: true,
                    message: "Nomini yozing",
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
              <Form.Item
                name="price"
                label="Narxi"
                rules={[
                  {
                    required: true,
                    message: "Narxini yozing",
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
              <Button
                type="primary"
                style={{
                  width: "100%",
                }}
                htmlType="submit"
              >
                Qo'shish
              </Button>
            </Form>
          </Modal>
        </>
      </div>
    </>
  );
}
