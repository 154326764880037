import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { host } from "../../config";

const App = (props) => {
    const navigate = useNavigate();
    const [gold, setGold] = useState();
    const [dollar, setDollar] = useState();
    const location = useLocation();
    const token = localStorage.getItem("token");
    useEffect(() => {
        fetch(host + "/api/config", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                dollar: dollar,
                gold: gold,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.message === "Invalid token.") {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err.message));
    }, [gold, dollar]);

    return (
        <>
            <header>
                <ul className="nav-header">
                    <li
                        className={
                            location.pathname === "/shop" ? "selected-nav" : ""
                        }
                    >
                        <Link className="nav-link" to="/shop">
                            Main
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/clients"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-link" to="/shop/clients">
                            Clients
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/sellers"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-link" to="/shop/sellers">
                            Sellers
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/database"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-link" to="/shop/database">
                            Prices
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/base"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-link" to="/shop/base">
                            Base +
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/jizzax"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-link" to="/shop/jizzax">
                            Base -
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/cloud"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-link" to="/shop/cloud">
                            Cloud
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/shop/history"
                                ? "selected-nav"
                                : ""
                        }
                    >
                        <Link className="nav-link" to="/shop/history">
                            History
                        </Link>
                    </li>
                </ul>
                <ul className="header-list">
                    <li className="list-item">
                        Gold price:
                        <input
                            type="text"
                            onChange={(e) => setGold(e.target.value)}
                            defaultValue={
                                props.config?.gold_price
                                    ? props.config?.gold_price
                                    : ""
                            }
                        />
                        sum
                    </li>
                    <li className="list-item">
                        Dollar price:
                        <input
                            type="text"
                            onChange={(e) => setDollar(e.target.value)}
                            defaultValue={
                                props.config?.dollar_price
                                    ? props.config?.dollar_price
                                    : ""
                            }
                        />
                        sum
                    </li>
                </ul>
            </header>
        </>
    );
};

export default App;
